import axios from 'axios';
import { CONNECTOR_VERSION_URL } from '../app/constants';

export async function getLatestConnectorVersionInfo() {
    if (!CONNECTOR_VERSION_URL) {
        return {}
    }

    try {
        const result = await axios.get(CONNECTOR_VERSION_URL);
        if (Number(result.status) !== 200) {
            return {};
        }
        return result.data;
    } catch (err) {
        return {};
    }
}